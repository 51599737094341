<template>
  <v-card class="elevation-x">
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12" md="6">
          <v-list-item>
            <font-awesome-icon fixed-width size="2x" :icon="['fal', 'user']" class="primary--text mr-2" />
            <v-list-item-content>
              <v-list-item-title class="font-alt">Nom complet</v-list-item-title>
              <v-list-item-subtitle>{{ patient.full_name || 'Non renseigné' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="6">
          <v-list-item>
            <font-awesome-icon fixed-width size="2x" :icon="['fal', 'map-marker-alt']" class="primary--text mr-2" />
            <v-list-item-content>
              <v-list-item-title class="font-alt">Adresse</v-list-item-title>
              <address-displayer :address="patient.address" />
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="6">
          <v-list-item>
            <font-awesome-icon fixed-width size="2x" :icon="['fal', 'phone']" class="primary--text mr-2" />
            <v-list-item-content>
              <v-list-item-title class="font-alt">Téléphone</v-list-item-title>
              <v-list-item-subtitle>{{ formatPhoneNumber(patient.phone) || 'Non renseigné' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="6">
          <v-list-item>
            <font-awesome-icon fixed-width size="2x" :icon="['fal', 'at']" class="primary--text mr-2" />
            <v-list-item-content>
              <v-list-item-title class="font-alt">Adresse e-mail</v-list-item-title>
              <v-list-item-subtitle>{{ patient.email || 'Non renseigné' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import contactMethods from '@/mixins/contactMethods'
  import AddressDisplayer from '@/modules/core/components/AddressDisplayer'
  export default {
    name: 'PatientInformations',
    components: {AddressDisplayer},
    mixins: [contactMethods],
    props: {
      patient: {
        type: Object,
        required: false
      }
    }
  }
</script>
